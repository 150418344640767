@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  outline: 1px solid black;
} */

:root {
  --spotify-green: #1DB954;
  --spotify-green-hover: #1c9e4a;
  --spotify-black: #181818;
  --spotify-black-hover: #212121;
  --spotify-white: #fafafa;
  --spotify-hover-white: #bdbdbd;
  --spotify-grey: #5c5c5c;
  --top-bar-size: 60px;
  --max-nav-bar-height: 100px;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

:-webkit-any(article,aside,main,nav,section) h1 { font-size: 2em; }

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background-color: rgb(250, 250, 250, 0.3);
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(250, 250, 250, 0.7);
}

#root {
  max-height: 100vh;
}

body{
  margin: 0;
  background-color: var(--spotify-black);
  color: var(--spotify-white);
}

.navbar {
  max-height: var(--max-nav-bar-height);
}

.modal-header {
  border-bottom: 1px solid var(--spotify-hover-white);
}

.modal-content{
  background-color: var(--spotify-black);
  color: var(--spotify-white);
}

.modal-footer {
  border-top: 1px solid var(--spotify-hover-white);
}

.btn-close {
  background-color: var(--spotify-hover-white);
  transition: background-color 0.1s ease-in-out;
}

.btn-close:hover {
  background-color: var(--spotify-white);
}

aside {
  border-right: 1px solid var(--spotify-grey);
}

h1 {
  margin: 10px;
}

.playlistItem, .trackItem {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
  margin-bottom: -1px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  border-top: 1px solid var(--spotify-grey);
  border-bottom: 1px solid var(--spotify-grey);
  color: inherit;
  text-decoration: inherit;
}

.playlistItem.active {
  background-color: #3f3f3f;
}

.playlistItem:hover, .trackItem:hover {
  background-color: var(--spotify-black-hover);
  color: inherit;
  text-decoration: inherit;
}

.playlistFunctions, .ownedPlaylistsTitle {
  height: var(--top-bar-size);
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid;
}

.modal-body > .trackItem {
  grid-template-columns: 100px 1fr;
}

.modal-body > .trackItem > img {
  width: 100px;
}

.ownedPlaylistsTitle > h1 {
  height: 40px;
  margin: 0;
}

.playlistFunctions > button {
  height: 40px;
}

.trackList {
  height: calc(100vh - var(--top-bar-size) - var(--max-nav-bar-height));
  overflow-y: auto;
}

.btn-primary {
  background-color: transparent;
  border-color: transparent;
  color: var(--spotify-white);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: var(--spotify-black-hover);
  border-color: var(--spotify-grey);
  color: var(--spotify-white);
}

.btn-primary:focus, .btn-primary:active {
  box-shadow: none;
}

.btn-spotify {
  background-color: transparent;
  border-color: var(--spotify-green);
  color: var(--spotify-white);
}

.btn-spotify:hover, .btn-spotify:focus, .btn-spotify:active {
  background-color: var(--spotify-black-hover);
  border-color: var(--spotify-green-hover);
  color: var(--spotify-white);
}

.btn-spotify:focus, .btn-spotify:active {
  box-shadow: none;
}

.text-spotify {
  color: var(--spotify-green);
}

.modal-body {
  padding: 0px;
}

.spotifyLogInCard {
  width: calc(100vw / 4);
  margin: 10vh auto;
  background-color: var(--spotify-black);
  color: var(--spotify-white);
  border: 1px solid var(--spotify-grey);
}

.spotifyLogInCard > .card-body {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
}

.spotifyLogInCard > .card-body > hr {
  border: 1px solid var(--spotify-grey);
  width: 100%;
  margin: 0.5rem 0 1rem 0;
}

/* .spotifyLogInCard > .card-body > h1 {
  margin-bottom: 2rem;
} */